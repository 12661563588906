import React from 'react'
import styled from '@emotion/styled'
import Paper from '@mui/material/Paper'
import MuiContainer from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import theme from 'theme'

export const BackgroundContainer = styled(Paper)`
  padding: ${theme.spacing(2)}};
  overflow-x: auto;
`

export const Container = styled(MuiContainer)`
  padding-bottom: ${theme.spacing(15)};

  ${theme.breakpoints.down('sm')} {
    padding-left: ${theme.spacing(0.5)} !important;
    padding-right: ${theme.spacing(0.5)} !important;
  }
`

export const PaddedBlock = styled.div`
  margin: ${theme.spacing(2)} ${theme.spacing(0)} ${theme.spacing(1)} ${theme.spacing(0)};
`

export const ErrorText: React.FC<{ style?: any; error: string }> = ({ error, style }) => {
  if (!error) {
    return null
  }

  return (
    <Typography style={style || {}} color='error'>
      {error}
    </Typography>
  )
}

export const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children)
