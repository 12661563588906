import React, { useContext, useEffect, useMemo } from 'react'
import MuiTextField from '@mui/material/TextField'
import { ErrorText, PaddedBlock } from '../Containers'
import { useField, useFormikContext } from 'formik'
import Autocomplete from '@mui/material/Autocomplete'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { LoadingContext } from '../Contexts/LoadingContext'
import Box from '@mui/material/Box'
import uniqueId from 'lodash/uniqueId'

interface GoogleAddressAutocompleteInterface {
  name: string
  label: string
  locationAttributeName?: string
}

// Material-UI will not apply Mui-focused if selection happens with keys
// so we apply inline styles as required for focused element
const styles = {
  focused: { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
}

const GoogleAddressAutocomplete: React.FC<GoogleAddressAutocompleteInterface> = (props) => {
  const { name, locationAttributeName, label } = props

  const { setFieldValue, setFieldError } = useFormikContext()
  const [{ onChange, value, ...otherFormikFieldProps }, meta] = useField({ name })

  const locationAttrName = locationAttributeName ?? `${name}Location`
  const locationField = useField({ name: locationAttrName })
  const locationFieldMeta = locationField[1]

  const { setLoading } = useContext(LoadingContext)

  const handlePlacesChange = (address) => {
    setFieldValue(name, address)
  }

  const loadingId = useMemo(() => uniqueId('address-autocomplete-'), [])

  const handlePlacesSelect = (address) => {
    setFieldValue(name, address)
    setLoading(loadingId, true)
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng: any) => {
        setFieldValue(locationAttrName, { type: 'Point', coordinates: [latLng.lng, latLng.lat] })
        setLoading(loadingId, false)
      })
      .catch((error) => {
        setFieldError(name, error)
        setLoading(loadingId, false)
      })
  }

  useEffect(() => {
    return () => setLoading(loadingId, false)
  }, [])

  return (
    <PlacesAutocomplete
      value={value}
      onChange={handlePlacesChange}
      onSelect={handlePlacesSelect}
      debounce={300}
      shouldFetchSuggestions={value.length > 3}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        return (
          <PaddedBlock>
            <Autocomplete
              value={value}
              options={suggestions}
              getOptionLabel={(option: any) => (typeof option === 'string' ? option : option.description)}
              filterOptions={(x) => x}
              loading={loading}
              renderOption={(props, option) => {
                const { onClick, onTouchStart, ...acProps } = props
                const placesProps = getSuggestionItemProps(option)

                const fullOnClick = (e) => {
                  placesProps.onClick(e)
                  onClick(e)
                }

                const fullOnTouchStart = (e) => {
                  placesProps.onTouchStart(e)
                  onTouchStart(e)
                }

                return (
                  <Box
                    {...placesProps}
                    {...acProps}
                    style={option.active ? styles.focused : {}}
                    onClick={fullOnClick}
                    onTouchStart={fullOnTouchStart}
                    component='li'
                  >
                    {option.description}
                  </Box>
                )
              }}
              renderInput={(params) => {
                return (
                  <MuiTextField
                    fullWidth
                    name={name}
                    label={label}
                    error={!!meta.error || !!locationFieldMeta.error}
                    {...params}
                    {...getInputProps()}
                    value={value}
                  />
                )
              }}
            />
            <ErrorText error={meta.error || locationFieldMeta.error} />
          </PaddedBlock>
        )
      }}
    </PlacesAutocomplete>
  )
}

export default GoogleAddressAutocomplete
