import React, { useContext, useEffect, useState } from 'react'
import { NumericFormat, NumericFormatProps } from 'react-number-format'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { FormikContext, useField } from 'formik'
import { ErrorText, PaddedBlock } from '../Containers'

const DecimalInputField: React.FC<TextFieldProps> = (props) => {
  const { name, ...otherProps } = props

  const { setFieldValue } = useContext(FormikContext)
  const [{ value = 0 }, meta] = useField({ name })

  const [backingValue, setBackingValue] = useState<string>(() => {
    return value.toString()
  })

  useEffect(() => {
    setBackingValue(value.toString())
  }, [value])

  const onChange = (e) => {
    const { value, floatValue } = e.target
    setBackingValue(value)
    return setFieldValue(name, floatValue)
  }

  return (
    <PaddedBlock>
      <TextField
        value={backingValue}
        onChange={onChange}
        fullWidth
        name={name}
        error={!!meta.error}
        {...otherProps}
        InputProps={{
          inputComponent: DecimalInputCustom,
          inputProps: { decimalScale: 2 },
        }}
      />
      <ErrorText error={meta.error} />
    </PaddedBlock>
  )
}

const DecimalInputCustom = React.forwardRef<unknown, NumericFormatProps & { onChange: (v: any) => void }>(
  (props, ref) => {
    const { onChange, decimalScale, ...rest } = props

    return (
      <NumericFormat
        {...rest}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({ target: { name: props.name, value: values.value, floatValue: values.floatValue } })
        }}
        thousandSeparator={"'"}
        decimalScale={decimalScale}
        decimalSeparator=','
        valueIsNumericString
        allowNegative={false}
      />
    )
  }
)

export default DecimalInputField
