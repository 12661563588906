import { createContext } from 'react'

export const SnackContext = createContext({
  snackOpen: false,
  snackText: '',
  detailedMessages: [],
  snackSeverity: 'warning',
  setSnack: ({ snackOpen: boolean, snackText: String, snackSeverity: Color, detailedMessages = []}) => {},
})

export default SnackContext
