import axios, { AxiosResponse } from 'axios'
import { getRecoil, resetRecoil, setRecoil } from 'recoil-nexus'
import { accessTokenAtom, refreshTokenAtom } from 'state'

interface AuthResponse {
  access_token: string
  token_type: string
  expires_in: number
  refresh_token: string
  created_at: number
}

const AUTH_URL = '/oauth/token'
const REVOKE_URL = '/oauth/revoke'
const LOGOUT_URL = '/logout'

axios.defaults.xsrfCookieName = 'CSRF-TOKEN'
axios.defaults.xsrfHeaderName = 'X-CSRF-Token'
axios.defaults.withCredentials = true

const env = document.querySelector<HTMLMetaElement>('meta[name=environment]')?.content

const parseResponse = async (response: AxiosResponse<AuthResponse>) => {
  const { access_token, refresh_token } = response.data

  setRecoil(accessTokenAtom, access_token)
  setRecoil(refreshTokenAtom, refresh_token)

  return response.data
}

const redirectToLogin = () => (window.location.pathname = '/login')

const redirectToRoot = () => {
  window.location.pathname = env === 'test' && window.location.hash == '#skip_root_redirect' ? '/platform_info' : '/'
}

export const getAccessToken = () => {
  const accessToken = getRecoil(accessTokenAtom)
  return accessToken ? `Bearer ${accessToken}` : null
}

let refreshPromise: Promise<AuthResponse> | null = null
export const refresh = async () => {
  if (refreshPromise) return refreshPromise
  refreshPromise = new Promise(async (resolve, reject) => {
    const refreshToken = getRecoil(refreshTokenAtom)

    if (!refreshToken) {
      reject(new Error('No refresh token found'))
    } else {
      const response = await axios.post<any, AxiosResponse<AuthResponse>>(AUTH_URL, {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      })
      resolve(parseResponse(response))
      refreshPromise = null
    }
  })
  return refreshPromise
}

export const login = async (email: string, password: string) => {
  const response = await axios.post<any, AxiosResponse<AuthResponse>>(AUTH_URL, {
    grant_type: 'password',
    email,
    password,
  })
  const parsedResponse = parseResponse(response)
  redirectToRoot()
  return parsedResponse
}

export const logout = async () => {
  const refreshToken = getRecoil(refreshTokenAtom)
  resetRecoil(accessTokenAtom)
  resetRecoil(refreshTokenAtom)
  await axios.all([axios.post(LOGOUT_URL), axios.post(REVOKE_URL, `token=${refreshToken}`)])
  redirectToLogin()
}
