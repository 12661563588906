import { gql, useLazyQuery } from '@apollo/client'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import { useField, useFormikContext } from 'formik'
import { GQLQuery } from 'graphqlSchema'
import { t } from 'i18n'
import debounce from 'lodash/debounce'
import uniqBy from 'lodash/uniqBy'
import React, { useCallback, useEffect, useState } from 'react'
import { Snack, SnackType } from '..'
import { ErrorText, PaddedBlock } from '../Containers'

interface LursoftAddressAutocompleteInterface {
  name: string
  label: string
  size?: 'small' | 'medium'
  locationAttributeName?: string,
  country?: string
}

const addressSearchQuery = gql`
  query AddressesSearch($term: String!, $country: String!) {
    addressesSearch(term: $term, country: $country) {
      nodes {
        id
        address
        addressLocation
      }
    }
  }
`

const LursoftAddressAutocomplete: React.FC<LursoftAddressAutocompleteInterface> = (props) => {
  const { name, locationAttributeName, label, size: sizeOverride } = props
  const { setFieldValue, setFieldError } = useFormikContext()
  const [{ onChange, value, ...otherFormikFieldProps }, meta] = useField({ name })

  const locationAttrName = locationAttributeName ?? `${name}Location`
  const locationField = useField({ name: locationAttrName })
  const locationFieldMeta = locationField[1]

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedAddress, setSelectedAddress] = useState<{ address: string; addressLocation: any }>({
    address: value,
    addressLocation: locationField[0].value,
  })
  const [options, setOptions] = useState([{ address: value, addressLocation: locationField[0].value }])
  const [inputValue, setInputValue] = useState(value)
  const [snack, setSnack] = useState<SnackType>({})

  const [refetch, ..._other] = useLazyQuery<GQLQuery>(addressSearchQuery, {
    notifyOnNetworkStatusChange: true,
    partialRefetch: true,
    variables: { term: inputValue, country: props.country || 'lv' },
  })

  const inputValueChange = (event, newInputValue) => {
    if (event) {
      setInputValue(newInputValue)
    }
  }

  const valueChange = (event, newOption) => {
    if (newOption) {
      setFieldValue(name, newOption.address)
      setFieldValue(locationAttrName, newOption.addressLocation)
    }
  }
  useEffect(() => {
    setFieldValue(name, value)
  }, [value])

  const isOptionEqualToValue = (option, value) => {
    return option.address === value || option.address === ''
  }

  const getOptionLabel = (option) => {
    return option?.address || (typeof option === 'string' ? option : '')
  }

  const loadAddresses = useCallback(
    debounce((currentInputValue, currentValue) => {
      setLoading(() => true)
      refetch({ variables: { term: currentInputValue, country: props.country || 'lv' } })
        .then((query) => {
          const newOptionsWithOriginal = uniqBy([].concat(query.data.addressesSearch.nodes), 'address')
          setOptions(() => {
            setLoading(() => false)
            return newOptionsWithOriginal
          })
        })
        .catch((error) => {
          setLoading(false)
          setSnack({ snackOpen: true, snackText: error.message, snackSeverity: 'error' })
        })
    }, 300),
    [props.country],
  )

  useEffect(() => {
    if (inputValue !== value && !!inputValue && open) {
      loadAddresses(inputValue, selectedAddress)
    }
    return () => loadAddresses.cancel()
  }, [inputValue])

  useEffect(() => {
    setSelectedAddress({ address: value, addressLocation: locationField[0].value })
    setOptions([{ address: value, addressLocation: locationField[0].value }])
    setInputValue(value)
  }, [value])

  return (
    <PaddedBlock>
      <Autocomplete
        filterOptions={(options) => options}
        noOptionsText={t('empty')}
        loadingText={t('loading')}
        options={options}
        onInputChange={inputValueChange}
        inputValue={inputValue}
        freeSolo={true}
        value={value}
        onChange={valueChange}
        loading={loading}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            error={!!meta.error || !!locationFieldMeta.error}
            InputProps={{
              ...params.InputProps,
              size: sizeOverride,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color='inherit' size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      <ErrorText error={meta.error || locationFieldMeta.error} />
      <Snack snack={snack} setSnack={setSnack} />
    </PaddedBlock>
  )
}

export default LursoftAddressAutocomplete
