import React, { useMemo, useCallback, useContext, useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import { DataGrid, DataGridProps, GridSortItem, GridSortModel } from '@mui/x-data-grid'
import theme from 'theme'
import styled from '@emotion/styled'
import { btoa } from 'utils'
import { LoadingContext } from 'components/common'
import uniqueId from 'lodash/uniqueId'
import CircularProgress from '@mui/material/CircularProgress'
import { errorMessage } from './ErrorBoundry'
import { t } from 'i18n'

const AutosizerZeroHeightWorkaround = styled(Paper)`
  > div[style] {
    height: auto !important;
  }
`

interface GqlConnection {
  totalCount: number
  nodes?: any[]
}

interface GqlTableReloadDataProps {
  first: number
  after: string
  sortField?: GridSortItem['field']
  sortDirection?: GridSortItem['sort']
}

type TableProps = {
  defaultPageSize?: number
  reloadData: (props: GqlTableReloadDataProps) => void
  connection?: GqlConnection
} & Partial<DataGridProps>

const styles = {
  defaultOverlay: {
    background: 'rgba(255, 255, 255, 0.75)',
    position: 'absolute',
    top: 56,
    bottom: 0,
    width: '100%',
    zIndex: 1000,
  } as React.CSSProperties,
  centerContent: {
    position: 'relative',
    textAlign: 'center',
    top: 'calc(50% - 20px)',
  } as React.CSSProperties,
}

const errorOverlay = ({ graphQLErrors, clientErrors, networkError, message, error }) => {
  const env = (document.querySelector('meta[name=environment]') as HTMLMetaElement)?.content
  return (
    <>
      {env === 'test' && JSON.stringify({ graphQLErrors, clientErrors, networkError, message })}
      {message && errorMessage(message)}
      {graphQLErrors?.length && message !== graphQLErrors[0].message && errorMessage(graphQLErrors[0].message)}
      {clientErrors?.length && errorMessage(JSON.stringify(clientErrors[0]))}
      {networkError && errorMessage(networkError)}
      {error && errorMessage(error)}
    </>
  )
}

const noRowsOverlay = () => {
  return (
    <div style={styles.defaultOverlay}>
      <div style={styles.centerContent}>{t('empty')}</div>
    </div>
  )
}

export type GqlTableColumn = DataGridProps['columns'][number]

const GqlTable = (props: TableProps) => {
  const { defaultPageSize = 25, reloadData, connection, loading, sortModel: defaultSortModel, ...other } = props

  const { setLoading } = useContext(LoadingContext)

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(defaultPageSize)
  const [sortModel, setSortModel] = useState<GridSortModel>(defaultSortModel || [])

  // This keeps prev rows, while data is loading
  const [rows, setRows] = useState<any[]>([])
  const [totalCount, setTotalCount] = useState<number>(0)

  const onPageModelChange = useCallback((pageModel) => {
    setPageSize(pageModel.pageSize)
    setPage(pageModel.page)
  }, [])

  const onSortModelChange = useCallback((model: GridSortModel) => {
    setSortModel(model)
  }, [])

  const loadingId = useMemo(() => uniqueId('gql-table-'), [])

  useEffect(() => {
    setLoading(loadingId, loading)
    return () => {
      setLoading(loadingId, false)
    }
  }, [loadingId, loading])

  useEffect(() => {
    const [sortItem, ...rest] = sortModel
    reloadData({
      first: pageSize,
      after: btoa(`${page * pageSize}`),
      sortDirection: sortItem?.sort,
      sortField: sortItem?.field,
    })
  }, [page, pageSize, JSON.stringify(sortModel)])

  useEffect(() => {
    if (connection) {
      setRows(connection.nodes)
      setTotalCount(connection.totalCount)
    }
  }, [connection])

  return (
    <AutosizerZeroHeightWorkaround>
      <DataGrid
        disableSelectionOnClick
        disableColumnMenu
        loading={loading}
        paginationModel={{ page, pageSize }}
        paginationMode='server'
        sortingMode='server'
        onPaginationModelChange={onPageModelChange}
        onSortModelChange={onSortModelChange}
        sortModel={sortModel}
        autoHeight
        rowHeight={theme.typography.fontSize * 2.125}
        rows={rows}
        headerHeight={36}
        rowCount={totalCount}
        components={{
          NoRowsOverlay: noRowsOverlay,
          ErrorOverlay: errorOverlay,
          LoadingOverlay: () => (
            <div style={styles.defaultOverlay}>
              <div style={styles.centerContent}>
                <CircularProgress></CircularProgress>
              </div>
            </div>
          ),
        }}
        {...(other as DataGridProps)}
      />
    </AutosizerZeroHeightWorkaround>
  )
}

export { GqlTable, GqlTableReloadDataProps }
