import React, { useContext, useEffect, useState } from 'react'
import { NumericFormat, NumericFormatProps } from 'react-number-format'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { FormikContext, useField } from 'formik'
import { ErrorText, PaddedBlock } from '../Containers'
import { InputAdornment } from '@mui/material'
import EuroIcon from '@mui/icons-material/Euro'
import round from 'lodash/round'
import multiply from 'lodash/multiply'
import divide from 'lodash/divide'

const EuroToCentInputField: React.FC<TextFieldProps> = (props) => {
  const { name, ...otherProps } = props
  const euroToCentConversion = (value: number) => (value ? round(multiply(value, 100)) : value)
  const centToEuroConversion = (value: number) => (value ? divide(value, 100) : value)

  const { setFieldValue } = useContext(FormikContext)
  const [{ value = 0 }, meta] = useField({ name })

  const [backingValue, setBackingValue] = useState<string>(() => {
    return centToEuroConversion(value).toString()
  })

  useEffect(() => {
    setBackingValue(centToEuroConversion(value).toString())
  }, [value])

  const onChange = (e) => {
    const { value, floatValue } = e.target

    // if previous value was deleted, zero is displayed but value is empty string; in that case use value as 0
    setBackingValue(value || 0)
    const convertedValue = euroToCentConversion(floatValue || 0)
    return setFieldValue(name, convertedValue)
  }

  return (
    <PaddedBlock>
      <TextField
        value={backingValue}
        onChange={onChange}
        fullWidth
        name={name}
        error={!!meta.error}
        {...otherProps}
        InputProps={{
          startAdornment: (
            <InputAdornment position={'start'}>
              <EuroIcon />
            </InputAdornment>
          ),
          inputComponent: CurrencyInputCustom,
          inputProps: { decimalScale: 2 },
        }}
      />
      <ErrorText error={meta.error} />
    </PaddedBlock>
  )
}

const CurrencyInputCustom = React.forwardRef<unknown, NumericFormatProps & { onChange: (v: any) => void }>(
  (props, ref) => {
    const { onChange, decimalScale, ...rest } = props

    return (
      <NumericFormat
        {...rest}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({ target: { name: props.name, value: values.value, floatValue: values.floatValue } })
        }}
        thousandSeparator={"'"}
        decimalScale={decimalScale}
        decimalSeparator=','
        valueIsNumericString
        allowNegative={false}
      />
    )
  }
)

export default EuroToCentInputField
