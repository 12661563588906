import styled from '@emotion/styled'
import LinearProgress from '@mui/material/LinearProgress'

const Loading = styled(LinearProgress)`
  margin-top: -4px;
  margin-right: 0px;
  margin-left: 0px;
  top: 0;
  opacity: 1;
  transition: opacity 200ms;
`

export default Loading
