import React from 'react'
import * as Sentry from '@sentry/react'

export const errorMessage = (error: Error | string) => {
  return (
    <div
      style={{
        color: '#800',
        backgroundColor: '#F8E8D2',
        padding: '10px 20px',
        margin: '10px 0px',
        fontWeight: 'bold',
      }}
    >
      Something went wrong. ({error.toString()})
    </div>
  )
}

export const ErrorBoundary = (props) => {
  return <Sentry.ErrorBoundary {...props} fallback={({ error }) => errorMessage(error)} />
}
