import React from 'react'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { GridRenderCellParams } from '@mui/x-data-grid'

const BooleanGridCell: React.FC<GridRenderCellParams> = ({ row, field }) => {
  return row[field] ? <CheckCircleOutlineIcon color='success' /> : <HighlightOffIcon color='error' />
}

export default BooleanGridCell
