import { atom } from 'recoil'
import { localStorageEffect } from '../effects.recoil'

enum AuthTokenAtomKeys {
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',
}

export const accessTokenAtom = atom<string>({
  key: AuthTokenAtomKeys.ACCESS_TOKEN,
  default: null,
  effects: [localStorageEffect(AuthTokenAtomKeys.ACCESS_TOKEN)],
})

export const refreshTokenAtom = atom<string>({
  key: AuthTokenAtomKeys.REFRESH_TOKEN,
  default: null,
  effects: [localStorageEffect(AuthTokenAtomKeys.REFRESH_TOKEN)],
})
