import React from 'react'
import MuiSwitch from '@mui/material/Switch'
import FormHelperText from '@mui/material/FormHelperText'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useField } from 'formik'
import { PaddedBlock } from '../Containers'

interface SwitchInterface {
  name: string
  label: string
  helperText?: string
}

const Switch: React.FC<SwitchInterface> = (props) => {
  const { name, label, helperText } = props
  const [{ onChange, value, ...otherFormikFieldProps }, meta] = useField({ name })

  return (
    <PaddedBlock>
      <FormControlLabel
        style={{ margin: 0 }}
        labelPlacement='end'
        label={label}
        control={<MuiSwitch checked={(value as boolean) || false} name={name} onChange={onChange} />}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </PaddedBlock>
  )
}

export default Switch
