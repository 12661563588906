import React from 'react'
import Typography from '@mui/material/Typography'

const Subtitle = ({ children, ...otherpProps }) => {
  return (
    <Typography {...otherpProps} variant='h3'>
      {children}
    </Typography>
  )
}

export default Subtitle
