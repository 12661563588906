import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

const useSearch = () => {
  const location = useLocation()
  const query = useMemo(() => new URLSearchParams(location.search), [location.search])
  const term = query.get('term')
  const time = query.get('time')

  return { term, time }
}

export default useSearch
