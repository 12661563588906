import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import Button, { ButtonProps } from '@mui/material/Button'
import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link'
import { Fab, FabProps } from './Fab'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'

export const LinkFab: React.FC<LinkProps & FabProps> = (props) => <Fab {...props} component={Link as any} />

export const LinkButton: React.FC<ButtonProps & LinkProps> = (props) => <Button {...props} component={Link as any} />

export const LinkIconButton: React.FC<IconButtonProps & LinkProps> = (props) => (
  <IconButton {...props} component={Link as any} />
)

export const LinkPlain: React.FC<MuiLinkProps & LinkProps> = (props) => <MuiLink {...props} component={Link as any} />

export const LinkIfAllowed: React.FC<MuiLinkProps & LinkProps & { allowed: boolean }> = (props) => {
  const { allowed, ...muiProps } = props

  if (allowed) {
    return <MuiLink {...muiProps} component={Link as any} />
  } else {
    return <>{props.children}</>
  }
}
