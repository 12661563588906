import styled from '@emotion/styled'
import MuiFab, { FabProps as MuiFabProps } from '@mui/material/Fab'

const fixedOffset = 32
const fabDiameter = 56

type PostitionProps = {
  position?: number
}

export type FabProps = PostitionProps & MuiFabProps

export const Fab = styled(MuiFab)`
  z-index: 1100;
  top: auto;
  right: ${(props: PostitionProps) => (props.position || 0) * (fabDiameter + fixedOffset / 2) + fixedOffset}px;
  bottom: ${fixedOffset}px;
  left: auto;
  position: fixed !important;
`
