import TextField, { TextFieldProps } from '@mui/material/TextField'
import { FormikContext, useField } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { ErrorText, PaddedBlock } from '../Containers'

const PaddedTextField: React.FC<
  TextFieldProps & { asMoneyCents?: boolean; asDecimal?: boolean; inputValue?: any; skipPadding?: boolean }
> = (props) => {
  const { name, asMoneyCents, asDecimal, inputValue: defaultInputValue, skipPadding, ...otherProps } = props

  //Used for asMoneyCents or asDecimal cases
  const [inputValue, setInputValue] = useState(defaultInputValue)

  const { setFieldValue } = useContext(FormikContext)
  const [{ onChange, value, ...otherFormikFieldProps }, meta] = useField({ name })

  let handler = onChange
  if (asMoneyCents || asDecimal) {
    handler = (e) => {
      const sanitizedValue = e.target.value.replace(/[^1234567890.,-]/gim, '')
      setInputValue(sanitizedValue)
      const parsedValue = parseFloat(sanitizedValue.toString().replaceAll(',', '.')) * (asMoneyCents ? 100 : 1)
      const valueToSet = asMoneyCents ? Math.round(parsedValue) : parsedValue
      setFieldValue(name, !isNaN(valueToSet) ? valueToSet : 0)
    }
  }

  useEffect(() => {
    setInputValue(defaultInputValue)
  }, [defaultInputValue])

  const componentWithoutPadding = (
    <>
      <TextField
        value={asMoneyCents || asDecimal ? inputValue ?? '' : value ?? ''}
        fullWidth
        name={name}
        onChange={handler}
        error={!!meta.error}
        {...otherProps}
      />
      <ErrorText error={meta.error} />
    </>
  )

  return skipPadding ? componentWithoutPadding : <PaddedBlock>{componentWithoutPadding}</PaddedBlock>
}

export default PaddedTextField
