import React from 'react'
import Select, { SelectProps } from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import CircularProgress from '@mui/material/CircularProgress'
import InputLabel from '@mui/material/InputLabel'
import { ErrorText, PaddedBlock } from '../Containers'
import { TextFieldProps } from '@mui/material/TextField'
import { useField } from 'formik'

const PaddedSelect: React.FC<TextFieldProps & SelectProps & { loading?: boolean; skipPadding?: boolean }> = (props) => {
  const {
    name,
    loading,
    label,
    children,
    multiple,
    size,
    variant,
    skipPadding,
    InputLabelProps,
    renderValue,
    disabled,
    id,
  } = props

  const [{ onChange, value, ...otherFormikFieldProps }, meta] = useField({ name })

  const labelId = `${name}-${new Date().getTime()}`

  const defaultValue = multiple ? [] : ''

  const componentWithoutPadding = (
    <>
      <FormControl fullWidth>
        <InputLabel id={labelId} {...InputLabelProps}>
          {loading && <CircularProgress size={16} />}
          {label}
        </InputLabel>
        <Select
          id={id}
          disabled={disabled}
          variant={variant}
          size={size}
          multiple={multiple}
          value={value || defaultValue}
          name={name}
          onChange={onChange}
          error={!!meta.error}
          label={label}
          labelId={labelId}
          renderValue={renderValue}
        >
          {children}
        </Select>
      </FormControl>
      <ErrorText error={meta.error} />
    </>
  )

  return skipPadding ? componentWithoutPadding : <PaddedBlock>{componentWithoutPadding}</PaddedBlock>
}

export default PaddedSelect
