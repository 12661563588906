import { LocalizationProviderProps } from '@mui/lab/LocalizationProvider'
import MuiLocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { currentUserLocaleSelector } from '../../state/user/current-user.atom'
import React from 'react'
import { useRecoilValueLoadableOrDefault } from '../../state'
import lv from 'date-fns/locale/lv'

const LocalizationProvider: React.FC<Omit<LocalizationProviderProps, 'locale' | 'dateAdapter'>> = ({
  children,
  ...rest
}) => {
  const currentLocale = useRecoilValueLoadableOrDefault(currentUserLocaleSelector, lv)

  return (
    <MuiLocalizationProvider dateAdapter={AdapterDateFns} locale={currentLocale} {...rest}>
      {children}
    </MuiLocalizationProvider>
  )
}

export default LocalizationProvider
