import React, { useContext, useEffect, useState } from 'react'
import 'tinymce/tinymce'
import 'tinymce/models/dom'
import 'tinymce/icons/default'
import 'tinymce/themes/silver'
import 'tinymce/plugins/link'
import 'tinymce/plugins/table'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/code'
import 'tinymce/skins/ui/oxide/skin.min.css'
import { Editor } from '@tinymce/tinymce-react'
import { FormikContext, useField } from 'formik'
import { PaddedBlock } from './Containers'

const TinyMCE = ({ name }) => {
  const [{ onChange, value, ...otherFormikFieldProps }, meta] = useField({ name })
  const { setFieldValue } = useContext(FormikContext)

  const [initialValue, setInitialValue] = useState(value)

  const changeEvent = (content) => {
    if (value !== content) {
      setFieldValue(name, content)
    }
  }

  return (
    <PaddedBlock>
      <Editor
        value={value}
        initialValue={initialValue}
        textareaName={name}
        init={{
          skin: false,
          content_css: false,
          height: 300,
          menubar: false,
          branding: false,
          forced_root_block: 'div',
          plugins: ['link', 'table', 'lists', 'code'],
          toolbar:
            'undo redo paste | blocks bold italic | forecolor backcolor | \
                                    alignleft aligncenter alignright alignjustify | \
                                    bullist numlist | outdent indent link | table code',
        }}
        onEditorChange={changeEvent}
      />
    </PaddedBlock>
  )
}

export default TinyMCE
