import Alert, { AlertColor } from '@mui/material/Alert'
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar'
import React from 'react'

type SnackType = {
  snackOpen?: boolean
  snackSeverity?: AlertColor
  snackText?: string
}

export type SnackProps = {
  snack: SnackType
  setSnack: Function
} & Omit<SnackbarProps, 'anchorOrigin' | 'open' | 'autoHideDuration' | 'onClose'>

const Snack: React.FC<SnackProps> = ({ snack, setSnack, ...restProps }) => {
  return (
    <Snackbar
      {...restProps}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={snack.snackOpen}
      autoHideDuration={10000}
      onClose={() => setSnack({ snackOpen: false, snackSeverity: snack.snackSeverity, snackText: snack.snackText })}
    >
      <Alert elevation={6} variant='filled' severity={snack.snackSeverity as AlertColor}>
        {snack.snackText}
      </Alert>
    </Snackbar>
  )
}

export { SnackType }
export default Snack
