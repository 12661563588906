import { ApolloClient, InMemoryCache, HttpLink, Observable } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { getAccessToken, logout, refresh } from 'auth'

const clientApp = (document.querySelector('meta[name=client-app]') as HTMLMetaElement)?.content

const httpLink = new HttpLink({
  uri: '/graphql',
})

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'Client-App': clientApp,
      authorization: getAccessToken(),
    },
  }
})

const errorLink = onError(({ networkError, operation, forward }) => {
  if (networkError && networkError['statusCode'] === 401) {
    return new Observable((observer) => {
      refresh()
        .then((authResponse) => {
          const oldHeaders = operation.getContext().headers

          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: 'Bearer ' + authResponse.access_token,
            },
          })

          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          }

          forward(operation).subscribe(subscriber)
        })
        .catch((error: Error) => {
          logout()
          observer.error(error.message)
        })
    })
  }
})

const link = authLink.concat(errorLink).concat(httpLink)

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'cache-first',
    },
    watchQuery: { fetchPolicy: 'cache-and-network' },
  },
})

export default client
