import { atom, AtomEffect, DefaultValue, selector } from 'recoil'
import { default as gqlClient } from '../../graphqlClient'
import { gql } from '@apollo/client'
import enGB from 'date-fns/locale/en-GB'
import lv from 'date-fns/locale/lv'
import ru from 'date-fns/locale/ru'
import { GQLI18nAvailableLocaleEnum, GQLRoleKindEnum } from '../../graphqlSchema'

const profileQuery = gql`
  query Profile {
    me {
      settings
      roles {
        kind
      }
      email
    }
  }
`

type UserData = {
  me: {
    settings: {
      language: GQLI18nAvailableLocaleEnum
    }
    roles: {
      kind: GQLRoleKindEnum
    }[]
    email: string
  }
}

const gqlQueryUserData = async () => {
  return (await gqlClient.query<UserData>({ query: profileQuery, fetchPolicy: 'no-cache' })).data
}

const currentUserSelector = selector<UserData>({
  key: 'currentUserQuery',
  get: gqlQueryUserData,
})

const currentUserLocaleEffect: AtomEffect<UserData> = ({ onSet }) => {
  onSet((newValue, oldValue) => {
    if (oldValue instanceof DefaultValue) {
      return
    }
    if (newValue.me.settings.language !== oldValue.me.settings.language) {
      window.location.reload()
    }
  })
}

const currentUserSyncEffect: AtomEffect<UserData> = ({ setSelf }) => {
  const timer = setInterval(() => {
    gqlQueryUserData().then((data) => setSelf(data))
  }, 120 * 1000)
  return () => clearInterval(timer)
}

const currentUserAtom = atom<UserData>({
  key: 'currentUser',
  default: currentUserSelector,
  effects: [currentUserLocaleEffect, currentUserSyncEffect],
})

const currentUserLanguageAtom = selector<GQLI18nAvailableLocaleEnum>({
  key: 'currentUserLanguage',
  get: async ({ get }) => {
    return GQLI18nAvailableLocaleEnum[get(currentUserAtom).me.settings.language]
  },
})

const currentUserLocaleSelector = selector<Locale>({
  key: 'currenUserLocale',
  get: ({ get }) => {
    const lang = get(currentUserLanguageAtom)
    switch (lang) {
      case GQLI18nAvailableLocaleEnum.en:
        return enGB
      case GQLI18nAvailableLocaleEnum.lv:
        return lv
      case GQLI18nAvailableLocaleEnum.ru:
        return ru
      default:
        return lv
    }
  },
})

const currentUserUnselectedLanguageSelector = selector<GQLI18nAvailableLocaleEnum>({
  key: 'currentUserUnselectedLanguage',
  get: async ({ get }) => {
    const currentUserLanguage = get(currentUserLanguageAtom)
    return currentUserLanguage === GQLI18nAvailableLocaleEnum.lv
      ? GQLI18nAvailableLocaleEnum.en
      : GQLI18nAvailableLocaleEnum.lv
  },
})

const currentUserRolesAtomSelector = selector<{ kind: GQLRoleKindEnum }[]>({
  key: 'currentUserRoles',
  get: async ({ get }) => {
    return get(currentUserAtom).me.roles
  },
})

const currentUserEmailSelector = selector<string>({
  key: 'currentUserEmail',
  get: async ({ get }) => {
    return get(currentUserAtom).me.email
  },
})

export {
  currentUserLanguageAtom,
  currentUserRolesAtomSelector,
  currentUserEmailSelector,
  currentUserUnselectedLanguageSelector,
  currentUserAtom,
  currentUserLocaleSelector,
}
