import React from 'react'
import { MapContainer, useMap, GeoJSON, AttributionControl } from 'react-leaflet'
import { BaseLayer } from 'leafletSetup'
import styled from '@emotion/styled'
import { LatLngBounds, LatLngExpression, LeafletEventHandlerFnMap, StyleFunction } from 'leaflet'
import { GQLGeoJson } from 'graphqlSchema'

const defaultCenter: LatLngExpression = [56.9496, 24.1052]
const defaultZoom = 13

const Container = styled(MapContainer)`
  min-height: 20rem;
`

interface Props {
  geoJson: GQLGeoJson
  geoJsonStyle?: StyleFunction
  onEachFeature?: any
  mapStyle?: React.CSSProperties
}

const Map: React.FC<Props> = ({ geoJson, onEachFeature, geoJsonStyle }) => {
  const map = useMap()

  const defaultGeoJsonStyle = { color: 'red', weight: 1, fillColor: 'red', fillOpacity: 0.1 }
  const geoJsonEventHandlers: LeafletEventHandlerFnMap = {
    add: (e) => {
      try {
        const bounds: LatLngBounds = e.sourceTarget.getBounds()
        bounds.isValid() && map.fitBounds(bounds.pad(0.1))
      } catch (error) {
        console.error(error)
      }
    },
  }

  return (
    <>
      <BaseLayer />
      <GeoJSON
        data={geoJson}
        style={geoJsonStyle || defaultGeoJsonStyle}
        eventHandlers={geoJsonEventHandlers}
        onEachFeature={onEachFeature}
        key={JSON.stringify(geoJson)}
      />
    </>
  )
}

const ContainedMap: React.FC<Props> = (props) => {
  return (
    <Container
      style={props.mapStyle}
      center={defaultCenter}
      zoom={defaultZoom}
      scrollWheelZoom={false}
      attributionControl={false}
    >
      <Map key={new Date().getTime() + Math.random()} {...props} />
      <AttributionControl position='bottomright' prefix={false} />
    </Container>
  )
}

export default ContainedMap
