import React, { useState } from 'react'

import { Button, CircularProgress, IconButton, Typography } from '@mui/material'

import ClearIcon from '@mui/icons-material/Clear'

import { directUpload, getFileMetadata } from 'upload_utils'

import { GQLMutation, MutationToCreateDirectUploadArgs } from 'graphqlSchema'
import { gql, useMutation } from '@apollo/client'
import { t } from 'i18n'

const uploadMutation = gql`
  mutation CreateDirectUpload($input: DirectUploadInput!) {
    createDirectUpload(input: $input) {
      directUpload {
        blobId
        signedBlobId
        url
        headers
      }
    }
  }
`

const FileUploader = (props) => {
  const [file, setFile] = useState(null)
  const [inputKey, setInputKey] = useState(new Date().getTime())
  const [isSelected, setIsSelected] = useState(false)
  const [isObtaining, setIsObtaining] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [uploadMessage, setUploadMessage] = useState(null)

  const [createDirectUpload] = useMutation<GQLMutation, MutationToCreateDirectUploadArgs>(uploadMutation)

  const handleSelected = (e) => {
    setFile(e.target.files[0])
    setUploadMessage(null)
    setIsSelected(true)
  }

  const clear = () => {
    setIsUploading(false)
    setIsObtaining(false)
    setIsSelected(false)
    setInputKey(new Date().getTime())
    setFile(null)
  }

  const handleUpload = () => {
    setIsObtaining(true)
    getFileMetadata(file).then((metadata: any) => {
      const input = {
        ...metadata,
        record: {
          type: props.recordType,
          id: props.recordId,
          action: !!props.recordId ? 'update' : 'create'
        }
      }
      return createDirectUpload({
        variables: { input },
      }).then((returnData) => {
        setIsObtaining(false)
        setIsUploading(true)
        const {
          data: {
            createDirectUpload: {
              directUpload: { url, headers, signedBlobId },
            },
          },
        } = returnData
        return directUpload(url, JSON.parse(headers), file).then(() => {
          setUploadMessage(t('file_uploader.file_uploaded', { name: file.name }))
          props.uploaded && props.uploaded({ signedBlobId })
          clear()
        })
      })
    })
  }

  const helpText = props.helpText

  let labelText = t('file_uploader.no_file_selected')
  if (isSelected)
    labelText = t('file_uploader.file_selected', {
      name: file?.name,
      size: `${Math.round((file?.size || 0) / 1024)}KB`,
    })
  if (uploadMessage) labelText = uploadMessage

  let statusText = ''
  if (isObtaining) statusText = t('file_uploader.obtaining_url')
  if (isUploading) statusText = t('file_uploader.uploading_file')

  const isClearable = isSelected && !isUploading && !isObtaining

  const isObtainingOrUploading = isObtaining || isUploading

  return (
    <>
      <p>
        {labelText} {statusText}
        {helpText && (
          <>
            <br />
            <Typography color='gray' component='span'>
              {helpText}
            </Typography>
          </>
        )}
      </p>
      <Button variant='contained' component='label' disabled={isObtainingOrUploading}>
        {t('file_uploader.select_button')}
        <input id="uploadButton" key={inputKey} type='file' hidden onChange={handleSelected} />
      </Button>
      <Button
        onClick={handleUpload}
        style={{ marginLeft: 4 }}
        variant='contained'
        disabled={isObtainingOrUploading || !isSelected}
      >
        {t('file_uploader.upload_button')}
        {isObtainingOrUploading && <CircularProgress color='secondary' size={20} style={{ marginLeft: 4 }} />}
      </Button>
      {isClearable && (
        <IconButton style={{ marginLeft: 4 }} aria-label='clear' onClick={clear} size='small'>
          <ClearIcon />
        </IconButton>
      )}
    </>
  )
}

export { FileUploader }
