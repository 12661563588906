import React from 'react'
import { LinkPlain, IconHome } from '.'
import { Breadcrumbs, Typography } from '@mui/material'
import map from 'lodash/map'
import styled from '@emotion/styled'
import mainTheme from 'theme'

const HomeIcon = styled(IconHome)`
  height: ${({ theme }) => (theme as typeof mainTheme).typography.fontSize * 1.5}px;
`

interface Breadcrumb {
  text: string
  url?: string
}

interface Props {
  title?: string
  breadcrumbs?: Breadcrumb[]
  skipHome?: boolean
  children?: React.ReactNode
}

export const Titlebar: React.FC<Props> = ({ title, breadcrumbs, skipHome, children }) => {
  const breadcrumbLinks = map(breadcrumbs, ({ text, url }, index) => {
    if (url) {
      return (
        <LinkPlain to={url} key={index}>
          {text}
        </LinkPlain>
      )
    } else {
      return <Typography key={index}>{text}</Typography>
    }
  })

  return (
    <Breadcrumbs separator='▸'>
      {!skipHome && (
        <LinkPlain to='/'>
          <HomeIcon viewBox='0 0 24 20' />
        </LinkPlain>
      )}
      {breadcrumbLinks}
      {title && <LinkPlain to='#'>{title}</LinkPlain>}
      {children}
    </Breadcrumbs>
  )
}
