/** @jsx jsx */
import React from 'react'
import Grid from '@mui/material/Grid'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import theme from 'theme'
import { jsx, css } from '@emotion/react'

const styles = {
  root: css`
    ${theme.breakpoints.down('md')} {
      flex-direction: column-reverse;
    }
  `,
  alert: css`
    margin-top: ${theme.spacing(2)};
    ${theme.breakpoints.down('md')} {
      margin-top: 0;
    }
  `,
  typography: css`
    line-height: 2rem;
    ${theme.breakpoints.down('md')} {
      font-size: 14px;
      line-height: 1.4rem;
    },
  `,
}

const GridWithInfo: React.FC<{ main: Function; info: Function }> = ({ main, info }) => {
  return (
    <Grid container css={styles.root} spacing={2}>
      <Grid item xs={12} md={6}>
        {main()}
      </Grid>
      <Grid item xs={12} md={6}>
        <Alert severity='info' css={styles.alert}>
          <Typography css={styles.typography}>{info()}</Typography>
        </Alert>
      </Grid>
    </Grid>
  )
}

export default GridWithInfo
