import { RecoilValue, useRecoilValueLoadable } from 'recoil'
import { useMemo } from 'react'

const useRecoilValueLoadableOrDefault = <T>(value: RecoilValue<T>, defaultValue: T) => {
  const loadable = useRecoilValueLoadable<T>(value)
  return useMemo(() => {
    switch (loadable.state) {
      case 'hasError':
      case 'loading':
        return defaultValue
      case 'hasValue':
        return loadable.contents
    }
  }, [loadable, defaultValue])
}

export { useRecoilValueLoadableOrDefault }
