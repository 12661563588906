import TextField, { TextFieldProps } from '@mui/material/TextField'
import { FormikContext, useField } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { ErrorText, PaddedBlock } from '../Containers'
import { isArray } from 'lodash'

const OrderNumberField: React.FC<
  TextFieldProps & {
    inputValue?: any
    skipPadding?: boolean
  }
> = (props) => {
  const { name, inputValue: defaultInputValue, skipPadding, ...otherProps } = props

  //Used for asMoneyCents or asDecimal cases
  const [inputValue, setInputValue] = useState(defaultInputValue)

  const { setFieldValue } = useContext(FormikContext)
  const [{ onChange, value, ...otherFormikFieldProps }, meta] = useField({ name })

  const handler = (e) => {
    const sanitizedValue = e.target.value.split('+')

    setInputValue(e.target.value)
    setFieldValue(name, sanitizedValue)
  }

  useEffect(() => {
    if (isArray(defaultInputValue)) {
      const sanitizedValue = defaultInputValue.join('+')

      setInputValue(sanitizedValue)
      setFieldValue(name, defaultInputValue)
    }
  }, [defaultInputValue])

  const componentWithoutPadding = (
    <>
      <TextField
        value={inputValue ?? ''}
        fullWidth
        name={name}
        onChange={handler}
        error={!!meta.error}
        {...otherProps}
      />
      <ErrorText error={meta.error} />
    </>
  )

  return skipPadding ? componentWithoutPadding : <PaddedBlock>{componentWithoutPadding}</PaddedBlock>
}

export default OrderNumberField
