import React from 'react'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { useField, useFormikContext } from 'formik'
import { ErrorText, PaddedBlock } from '../Containers'
import { DateFormat, formatDate } from 'utils'
import parse from 'date-fns/parse'
import { DateTimePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'

const PaddedDateTimeField: React.FC<TextFieldProps> = (props) => {
  const { name, ...otherProps } = props

  const [{ value, ...otherFormikFieldProps }, meta] = useField({ name })
  const { setFieldValue } = useFormikContext()

  const dateTimeValue = (value && formatDate(value, DateFormat.UI_FULL_DATETIME)) || ''

  const handleDateTimeChange = (e, fieldName) => {
      setFieldValue(fieldName, formatDate(parse(e.target.value, DateFormat.UI_FULL_DATETIME, new Date()), 'iso'))
  }

  const handleDateTimePickerChange = (e, fieldName) => {
    setFieldValue(fieldName, formatDate(e, 'iso'))
  }

  return (
    <PaddedBlock>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          value={value}
          mask={'__.__.____ __:__'}
          ampm={false}
          inputFormat={DateFormat.UI_FULL_DATETIME}
          toolbarFormat={DateFormat.UI_FULL_DATETIME}
          onChange={(e) => handleDateTimePickerChange(e, name)}
          renderInput={(params) => (
            <TextField
              {...params}
              value={dateTimeValue}
              fullWidth
              name={name}
              onChange={(e) => handleDateTimeChange(e, name)}
              error={!!meta.error}
              InputLabelProps={{ shrink: true }}
              {...otherProps}
            />
          )}
        ></DateTimePicker>
      </LocalizationProvider>
      <ErrorText error={meta.error} />
    </PaddedBlock>
  )
}

export default PaddedDateTimeField
