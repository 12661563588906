import React, { useEffect, useState } from 'react'
import DesktopTimePicker from '@mui/lab/DesktopTimePicker'
import TextField from '@mui/material/TextField'
import { useField, useFormikContext } from 'formik'
import { ErrorText, PaddedBlock } from '../Containers'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import isValid from 'date-fns/isValid'
import LocalizationProvider from 'components/common/LocalizationProvider'

const PaddedTimeField: React.FC<{ name: string; label: string; disableOpenPicker?: boolean }> = (props) => {
  const FORMAT = 'HH:mm'

  const { name, disableOpenPicker, label } = props

  const [pickerValue, setPickerValue] = useState<Date>(null)

  const [{ value, ...otherFormikFieldProps }, meta] = useField({ name })
  const { setFieldValue } = useFormikContext()

  const handleTimeChange = (fieldName, newValue) => {
    if (isValid(newValue)) {
      setFieldValue(fieldName, format(newValue, FORMAT))
    }
    if(newValue == null) {
      setFieldValue(fieldName, null)
    }
  }

  useEffect(() => {
    setPickerValue(parse(value, FORMAT, new Date()))
  }, [value])

  return (
    <PaddedBlock>
      <LocalizationProvider>
        <DesktopTimePicker
          disableOpenPicker={disableOpenPicker}
          value={pickerValue}
          label={label}
          readOnly={false}
          onChange={(newValue) => handleTimeChange(name, newValue)}
          renderInput={(params) => <TextField {...params} fullWidth error={!!meta.error} />}
        />
      </LocalizationProvider>
      <ErrorText error={meta.error} />
    </PaddedBlock>
  )
}

export default PaddedTimeField
