import React, { useState, createContext, useContext, SetStateAction, Dispatch } from 'react'

const SkipPaddingsContext = createContext<boolean>(false)
const SkipPaddingsActionsContext = createContext<Dispatch<SetStateAction<boolean>>>(undefined)

export const useSkipPaddingsContext = () => {
  const ctx = useContext(SkipPaddingsContext)
  if (ctx === undefined) {
    throw new Error(`useSkipPaddingsContext must be used withing a SkipPaddingsContextProvider.`)
  }
  return ctx
}

export const useSkipPaddingsActionsContext = () => {
  const ctx = useContext(SkipPaddingsActionsContext)
  if (ctx === undefined) {
    throw new Error(`useSkipPaddingsActionsContext must be used withing a SkipPaddingsContextProvider.`)
  }
  return ctx
}

const SkipPaddingsContextProvider = (props) => {
  const [skipPaddings, setSkipPaddings] = useState(false)

  return (
    <SkipPaddingsContext.Provider value={skipPaddings}>
      <SkipPaddingsActionsContext.Provider value={setSkipPaddings}>
        {props.children}
      </SkipPaddingsActionsContext.Provider>
    </SkipPaddingsContext.Provider>
  )
}

export default SkipPaddingsContextProvider
